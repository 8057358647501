import { render, staticRenderFns } from "./LoginDetails.vue?vue&type=template&id=c0cc5bf8&scoped=true&"
import script from "./LoginDetails.vue?vue&type=script&lang=js&"
export * from "./LoginDetails.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c0cc5bf8",
  null
  
)

export default component.exports
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import qInstall from "../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QToggle});
