<template>
  <FormSection
    :icon="isActive ? 'mdi-briefcase' : 'mdi-briefcase-outline'"
    :is-active="isActive"
    v-on="$listeners"
  >
    <template #title>Business Details</template>
    <template #description>
      Input relevant information about the user to establish a comprehensive
      profile, enabling efficient management and organization of key
      business-specific details.
    </template>

    <!-- job title -->

    <SelectField
      :value="jobTitle"
      label="job title"
      new-option
      :options="jobTitles"
      class="q-mb-lg"
      @input="updateJobTitle"
    />

    <!-- ... -->

    <!-- department -->

    <SelectField
      :value="department"
      label="department"
      new-option
      :options="departments"
      class="q-mb-lg"
      @input="updateDepartment"
    />

    <!-- ... -->

    <!-- manager -->

    <SelectField
      :value="managerId"
      label="manager"
      :options="managers"
      @input="updateManagerId"
    />

    <!-- ... -->
  </FormSection>
</template>

<script>
import SelectField from "@/components/common/form/select-field/SelectField.vue";
import FormSection from "@/components/common/item-builder/FormSection.vue";

import { user } from "@/api/factory.js";

export default {
  name: "BusinessDetails",

  components: { FormSection, SelectField },

  props: {
    jobTitle: {
      type: String,
      required: true,
    },

    department: {
      type: String,
      required: true,
    },

    managerId: {
      type: [String, Number],
      required: true,
    },

    isActive: {
      type: Boolean,
      default: false,
    },

    mode: {
      type: String,
      default: "NEW",
    },
  },

  data() {
    return {
      jobTitles: [],
      departments: [],
      managers: [],
    };
  },

  mounted() {
    this.getJobTitles();
    this.getDepartments();
    this.getManagers();
  },

  methods: {
    updateJobTitle(jobTitle) {
      this.$emit("update:jobTitle", jobTitle);
    },

    updateDepartment(department) {
      this.$emit("update:department", department);
    },

    updateManagerId(managerId) {
      this.$emit("update:managerId", managerId);
    },

    /* api functions */

    async getJobTitles() {
      const { error, payload } = await user.getUniqueColumnValues({
        column: "jobTitle",
      });

      if (error) {
        this.$alert.error("Error fetching Job Titles");
        return;
      }

      this.jobTitles =
        payload &&
        payload.map((jobTitle) => ({
          id: this.$nano.id(),
          label: jobTitle,
          value: jobTitle,
        }));
    },

    async getDepartments() {
      const { error, payload } = await user.getUniqueColumnValues({
        column: "department",
      });

      if (error) {
        this.$alert.error("Error fetching departments");
        return;
      }

      this.departments =
        payload &&
        payload.map((department) => ({
          id: this.$nano.id(),
          label: department,
          value: department,
        }));
    },

    async getManagers() {
      const { error, payload } = await user.getUserList();

      if (error) {
        this.$alert.error("Error fetching managers");
        return;
      }

      this.managers =
        payload &&
        payload.reduce((acc, cur) => {
          if (this.mode === "EDIT") {
            cur.id !== Number(this.$route.params.id) &&
              acc.push({
                id: this.$nano.id(),
                label: cur.value,
                value: Number(cur.id),
              });
          } else {
            acc.push({ id: this.$nano.id(), label: cur.value, value: cur.id });
          }

          return acc;
        }, []);
    },

    /* ... */
  },
};
</script>

<style lang="scss" scoped></style>
